@use "uswds-core" as *;

.usa-form {
  @include typeset(
    $theme-form-font-family,
    $theme-body-font-size,
    $theme-input-line-height
  );
  @include border-box-sizing;

  @include at-media("mobile-lg") {
    max-width: units("mobile");
  }

  // Don't show dotted underline with "required" asterisk because it can cause legibility issues ad appear as an ellipsis...
  abbr[title="required"] {
    text-decoration: none;
  }

  // Clear max-width in children since .usa-form has one of its own.
  // Used :where() to reduce specificity and
  // prevent usa-form styles from overriding `usa-input--[width] and `usa-input-group--[width]` classes.
  :where(.usa-input, .usa-textarea, .usa-select, .usa-range) {
    max-width: none;
  }

  .usa-button {
    margin-top: units(1);

    @include at-media("mobile-lg") {
      margin-top: units(3);
    }
  }

  // Avoids links styled as buttons
  a:where(:not(.usa-button)) {
    @include typeset-link;
  }
}

.usa-form--large {
  @include at-media("mobile-lg") {
    max-width: units("mobile-lg");
  }
}

.usa-show-password {
  @include button-unstyled;
  cursor: pointer;
}

.usa-form__note,
.usa-show-password {
  @include typeset($theme-form-font-family, "2xs", 3);
  float: right;
  margin: units(0.5) 0 units(2);
}
